.filter-items {
	margin-top: 20px;
}

.filter-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.filter-form-title {
	margin-top: 50px;
	display: flex;
	align-items: center;
}

.filter-form-title__label {
	width: 180px;
}

.filter-form-title__input {
	width: 300px;
}

.filter-select {
	min-width: 200px;
	margin-right: 10px;
}

.filter-form-select {
	min-width: 150px;
	margin-left: 10px !important;
	margin-top: 10px !important;
}

.filter-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.dashboard-filter-detail-page__header {
	display: flex;
	justify-content: space-between;
}