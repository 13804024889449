.drawer-answer-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__icon {
	margin: 0 5px;
	transform: scale(1.2)
}

.question-drawer .question-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
}

.question-drawer .answer-container {
	margin-bottom: 50px;
}

.question-drawer .question-condition-container {
	margin-bottom: 50px;
}

.question-drawer .btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .btn-container button {
	width: 300px;
}

.drawer-answer-item__answer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__image {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__check {
	width: 30%;
	display: flex;
	justify-content: center;
}

.drawer-answer-item__image .ant-typography {
	margin-right: 10px;
}

.answer-copy__btn {
	margin-bottom: 10px;
	margin-right: 10px;
}

.question-drawer .sub-question-container {
	margin-bottom: 50px;
}

.question-drawer .drawer-sub-question-item__sub-question {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .drawer-sub-question-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .drawer-sub-question-item__icon {
	margin: 0 5px;
	transform: scale(1.2)
}

.question-drawer .conditions-container {
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.question-drawer .condition-group-container {
	padding: 10px;
	background-color: #f0f0f0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.question-drawer .condition-group-container .condition-item {
	padding: 16px;
	background-color: #d1cece;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.operatorSelector {
	display: flex;
	justify-content: center;
	align-items: center;
}

.operatorButton {
	background: none;
	border: none;
	padding: 0;
	margin: 0 5px;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	/* background-color: #f0f0f0; */
}