.login-page {
  height: 100%;
}

.login-page__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page__content-card {
  width: 500px;
}

.login-page__content-card .ant-card-cover {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.login-page__content-card .ant-card-cover img {
  width: 150px;
  height: 150px;
}