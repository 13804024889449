.question-info-card {
	border: 1px solid #d9d9d9;
}

.question-info-card:hover {
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16), 0 3px 6px 0 rgba(0, 0, 0, .12), 0 5px 12px 4px rgba(0, 0, 0, .09);
}

.question-info-card__content {
	display: flex;
	padding: 20px;
	background: white;
	overflow: auto;
}

.question-info-card_footer {
	height: 45px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background: #fafafa;
	border-top: 1px solid #f0f0f0;
}

.question-info-card_text {
	width: 100%;
}

.question-info-card_title {
	display: flex;
	justify-content: space-between;
}

.question-info-card_description {
	display: grid;
	flex-wrap: nowrap;
	grid-template-columns: minmax(0, 1fr) min-content;
}

.question-info-card_vote {
	overflow-x: auto;
}

.question-info-card_stat {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 40px;

}

@media (max-width: 640px) {
	.question-info-card_stat {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		flex-basis: 100%;
		margin-top: 10px;
	}
}

.anticon-check-circle {
	display: none !important;
}

.question-info-card table {
	width: 100%;
}

.question-info-card table th {
	text-align: left;
	vertical-align: top;
	padding: 8px;
	white-space: nowrap;
	max-lines: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 9ch;
}

.question-info-card table td {
	text-align: center;
	padding: 8px;
}

.question-info-card table .table-buttons {
	white-space: nowrap;
	display: flex;
	justify-content: center;
}

.question-info-card table .table-meta-info {
	text-align: center;
}

.question-info-card td:first-child {
	text-align: left;
	white-space: wrap;
	width: 15ch;
	position: sticky;
	left: 0;
	background: white;
	z-index: 1;
}

.question-info-card th:first-child {
	text-align: left;
	white-space: wrap;
	min-width: 20ch;
	position: sticky;
	left: 0;
	background: white;
	z-index: 1;
}