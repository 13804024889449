.dashboard-layout {
	height: 100%;
}

.dashboard-layout .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

.dashboard-layout .trigger:hover {
	color: #1890ff;
}

.dashboard-layout .logo {
	height: 32px;
	margin: 16px;
	display: flex;
	justify-content: center;
}

.dashboard-layout .logo img {
	height: 32px;
}

.site-layout-header {
	background: #fff !important;
	padding: 0px !important;
}