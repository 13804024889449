.password-reset-page {
	height: 100%;
}

.password-reset-page__content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.password-reset-page__content-card {
	width: 500px;
}

.password-reset-page__content-card .ant-card-cover {
	margin-top: 25px;
	display: flex;
	justify-content: center;
}

.password-reset-page__content-card .ant-card-cover img {
	width: 150px;
	height: 150px;
}