.question-cards {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	align-self: flex-start;
	padding: 50px;
	width: 100%;
}

@media (max-width: 640px) {
	.question-cards {
		padding: 0px;
	}
}